<section class="main-content">
  <section class="header-section">
    <section class="title-section">
      <h1>Rules</h1>
    </section>
    <section class="actions-section">
      <section class="filter-section">
        <app-search-input
          [valueSubject]="searchValueSubject"
          [clearSubject]="searchClearSubject"
          [placeholder]="'Rule name'"
        ></app-search-input>
      </section>
      <section class="buttons-section">
        <button mat-mini-fab
                color="accent"
                data-type="button-create-rule"
                (click)="create()"
                matTooltip="Create a rule">
          <mat-icon>add</mat-icon>
        </button>
        <ng-container *ngIf="isGoogle">
          <button mat-mini-fab
                  color="accent"
                  data-type="button-download-csv"
                  (click)="downloadCsv()"
                  matTooltip="Download rules as CSV">
            <mat-icon>download</mat-icon>
          </button>
          <button mat-mini-fab
                  color="accent"
                  data-type="button-upload-rules"
                  (click)="fileInput.click()"
                  matTooltip="Upload rules in bulk">
            <mat-icon>upload</mat-icon>
          </button>
          <input data-type="input-import-csv" hidden type="file" accept=".csv" #fileInput
                 (change)="importCsv($event, fileInput)"/>
        </ng-container>
        <button mat-mini-fab
                color="accent"
                data-type="button-refresh-rule"
                (click)="refresh()"
                matTooltip="Refresh list">
          <mat-icon>refresh</mat-icon>
        </button>
      </section>
    </section>
  </section>

  <div class="card-container">
    <mat-card class="no-padding">
      <div class="spinner-container" *ngIf="loading$ | async">
        <mat-spinner></mat-spinner>
      </div>
      <section class="table-section">
        <table mat-table [dataSource]="dataSource" [hidden]="loading$ | async">
          <!-- id -->
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef><span>Rule name</span></mat-header-cell>
            <mat-cell *matCellDef="let element"><span data-type="rule-name"> {{element.name}} </span>
            </mat-cell>
          </ng-container>

          <!-- description -->
          <ng-container matColumnDef="description">
            <mat-header-cell class="xxl-cell" *matHeaderCellDef><span>Description</span></mat-header-cell>
            <mat-cell class="xxl-cell" *matCellDef="let element"><span class="text-ellipsis" data-type="rule-description"> {{element.description}} </span>
            </mat-cell>
          </ng-container>

          <!-- library -->
          <ng-container matColumnDef="libraryId">
            <mat-header-cell *matHeaderCellDef>Library</mat-header-cell>
            <mat-cell *matCellDef="let element"><span data-type="rule-library" data-type="rule-library">  {{element.library.name}}</span></mat-cell>
          </ng-container>

          <!-- document type -->
          <ng-container matColumnDef="documentType">
            <mat-header-cell class="xlg-cell" *matHeaderCellDef>Document class</mat-header-cell>
            <mat-cell class="xlg-cell" *matCellDef="let element"><span data-type="rule-document-type">  {{element.documentType.name}}</span></mat-cell>
          </ng-container>

          <!-- labels -->
          <ng-container matColumnDef="labels">
            <mat-header-cell class="xxl-cell" *matHeaderCellDef>
              <span *ngIf="isGoogle" data-type="rule-label-type">Gmail labels</span>
              <span *ngIf="isMicrosoft" data-type="rule-label-type">Folders</span>
            </mat-header-cell>
            <mat-cell class="xxl-cell" *matCellDef="let element">
              <span class="text-ellipsis" data-type="rule-label">{{ displayLabelList(element) }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="actions">
            <mat-header-cell class="md-cell" *matHeaderCellDef>Actions</mat-header-cell>
            <mat-cell class="md-cell" *matCellDef="let element">
              <button data-type="button-view-rule"
                      [attr.data-label]="element.name"
                      mat-icon-button
                      class="table-button"
                      (click)="view(element)"
                      matTooltip="View rule">
                <mat-icon>visibility</mat-icon>
              </button>

              <button data-type="button-edit-rule"
                      [attr.data-label]="element.name"
                      mat-icon-button class="table-button" (click)="edit(element)"
                      matTooltip="Edit rule">
                <mat-icon>edit</mat-icon>
              </button>
              <button data-type="button-delete-rule"
                      [attr.data-label]="element.name"
                      mat-icon-button class="table-button" (click)="confirmDelete(element)"
                      matTooltip="Delete rule">
                <mat-icon>delete</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns()"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns();"></mat-row>
          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4" data-type="rules-no-item">No item to display.</td>
          </tr>
        </table>
      </section>
      <section [hidden]="loading$ | async">
        <mat-paginator [pageSizeOptions]="[30]"></mat-paginator>
      </section>
    </mat-card>
  </div>
</section>
